import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2dc6fe6f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header flex flex-nowrap justify-end" }
const _hoisted_2 = { class: "flex flex-nowrap items-center gap-3" }
const _hoisted_3 = { class: "user-icon inline-block relative" }
const _hoisted_4 = { class: "user-icon inline-block relative" }
const _hoisted_5 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Register = _resolveComponent("Register")!
  const _component_SvgContainer = _resolveComponent("SvgContainer")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_Register),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.computedUser === null)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.register())),
            style: _normalizeStyle({ cursor: _ctx.computedUser ? 'default' : 'pointer' })
          }, _toDisplayString(_ctx.$t("register")), 5))
        : _createCommentVNode("", true),
      (_ctx.computedUser === null)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "menu-icon",
            style: _normalizeStyle({ cursor: _ctx.computedUser ? 'default' : 'pointer' }),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.login()))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_SvgContainer, { name: "headerRegisterIcon" })
            ])
          ], 4))
        : _createCommentVNode("", true),
      (_ctx.computedUser !== null && !_ctx.isWebView)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "menu-icon cursor-pointer",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openMenuProfile && _ctx.openMenuProfile(...args)))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "rounded-full",
                alt: _ctx.computedUser.nickName,
                src: _ctx.computedUser.image
              }, null, 8, _hoisted_5)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}