<template>
  <div :class="cssClass">
    <div>{{ $t("level") }}</div>
    <div class="flex justify-between mt-6 cursor-pointer">
      <div
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60 br60"
        :class="{ selected: competitive }"
        @click="click($event, true)"
      >
        {{ $t("competitive") }}
      </div>
      <div
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: !competitive }"
        @click="click($event, false)"
      >
        {{ $t("friendly") }}
      </div>
    </div>
    <div class="flex justify-center mt-6">
        <ProgressBar
          :firstValue="initLevel"
          :lastValue="endLevel"
          :value="endLevel"
          :showAllLevels="false"
        />
    </div>
  </div>
</template>

<script lang="ts">
import { ADD_LEVEL, ADD_LEVEL_REDPADEL, LevelProvider, LOW_LEVEL, LOW_LEVEL_REDPADEL, MAX_LEVEL, MAX_REDPADEL_LEVEL, MAX_SQUASH_LEVEL, MIN_LEVEL, Sport } from "@/enum/constants";
import { defineComponent } from "vue";
import ProgressBar from "@/components/form/progressBar.vue";

export default defineComponent({
  name: "LevelSelector",
  components: {
    ProgressBar
  },
  props: {
    modelValue: Boolean,
    cssClass: String,
    level: {
      type: Number,
      required: true
    },
    levelProvider: {
      type: Number,
      default: LevelProvider.PANDORA,
      required: false,
    },
    sport: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      initLevel: 0 as number,
      endLevel: 0 as number,
      competitive: true,
    };
  },
  created() {
    if (this.modelValue !== undefined) {
      this.competitive = this.modelValue;
    }
  },
  mounted(){
    this.setLevelLimits();
  },
  watch: {
    level() {
      this.setLevelLimits();
    },
    levelProvider() {
      this.setLevelLimits();
    },
    sport() {
      this.setLevelLimits();
    },
    
  },
  methods: {
    click(e: any, competitive: any) {
      this.competitive = competitive;
      this.$emit("update:modelValue", this.competitive);
    },
    setLevelLimits(){
      if (this.level !== undefined) {
        this.initLevel = Number((this.level - LOW_LEVEL).toFixed(2));
        this.endLevel = Number((this.level + ADD_LEVEL).toFixed(2));
        if (this.levelProvider === LevelProvider.REDPADEL){
          this.initLevel = Number((this.level - LOW_LEVEL_REDPADEL).toFixed(2));
          this.endLevel = Number((this.level + ADD_LEVEL_REDPADEL).toFixed(2));
        }
      }
      const maxLevel = (() => {
        if (this.sport === Sport.SQUASH) return MAX_SQUASH_LEVEL;
        if (this.levelProvider === LevelProvider.REDPADEL) return MAX_REDPADEL_LEVEL;
        return MAX_LEVEL;
      })();

      if (this.endLevel > maxLevel) {
        this.endLevel = maxLevel;
      }
      this.$emit("sendLevels", this.initLevel, this.endLevel);
    }
  },
});
</script>
<style>
</style>
