<template>
    <div class="progress-bar">
      <div
        class="progressBar br16 gradiend h-full"
        :style="{ width: barWidth + '%' }"
      ></div>
      <div class="level-labels">
        <span v-if="firstValue<MIN_LEVEL" class="init-level">{{NO_LEVEL_STRING_MARK}}</span>
        <span v-else class="init-level">{{ firstValue }}</span>
        <span
          class="level"
          v-for="level in levelsInRange"
          :key="level"
        >{{ level }}</span>
        <span v-if="showMidLevel" class="level">{{ midLevel }}</span>
        <span v-if="lastValue<MIN_LEVEL" class="last-level">{{NO_LEVEL_STRING_MARK}}</span>
        <span v-else class="last-level">{{ lastValue }}</span>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import { NO_LEVEL_STRING_MARK, MIN_LEVEL } from "@/enum/constants";

  export default defineComponent ({
    name: "ProgressBar",
    props: {
      firstValue: {
        type: Number,
        required: true,
      },
      lastValue: {
        type: Number,
        required: true,
      },
      value: {
        type: Number,
        required: true,
      },
      showAllLevels: {
        type: Boolean,
        required: false,
        default: true
      },
      showMidLevel: {
      type: Boolean,
      required: false,
      default: false,
    },
    },
    data() {
        return {
            totalLevels: this.lastValue - this.firstValue,
            NO_LEVEL_STRING_MARK: NO_LEVEL_STRING_MARK,
            MIN_LEVEL: MIN_LEVEL
        };
    },
    watch: {
      value() {
        this.$nextTick(() => {
          // Use $nextTick to ensure the DOM has been updated before accessing it
          this.totalLevels = this.lastValue - this.firstValue;
        });
      },
    },
    computed: {
      barWidth() {
        return ((this.value - this.firstValue) / this.totalLevels) * 100;
      },
      levelsInRange() {
        const levels = [];
          if(this.showAllLevels){
            for (let level = this.firstValue + 1; level < this.lastValue; level++) {
            levels.push(level);
          }
        }
        return levels;
      },
      midLevel() {
        return (this.firstValue + this.lastValue) / 2;
      },
    },
  });
  </script>
  <style scoped>
  .progress-bar {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .progressBar {
    background: linear-gradient(
      103.18deg,
      var(--color1) 20.29%,
      var(--color2) 97.77%
    );
    border-radius: 16px;
    transition: width 0.5s ease; /* Add transition property for smooth animation */    
  }
  
  .level-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
  
  .init-level,
  .last-level,
  .level {
    font-size: 14px;
    font-weight: bold;
    color: #e5e7eb; 
  }
  </style>
  