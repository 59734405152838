/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace Api
 * Marketplace Api description
 *
 * OpenAPI spec version: 1.0
 * Contact: develop@taykus.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Booking } from '../models';
import { MatchExternalId } from '../models';
import { MatchHash } from '../models';
import { MatchRequest } from '../models';
import { PreBooking } from '../models';
/**
 * MatchApi - axios parameter creator
 * @export
 */
export const MatchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a match
         * @param {MatchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMatch: async (body: MatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createMatch.');
            }
            const localVarPath = `/api/match`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a match
         * @param {MatchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMatchWithoutSale: async (body: MatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createMatchWithoutSale.');
            }
            const localVarPath = `/api/match/custom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers!['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all matches alive
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllMatches: async (start: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling findAllMatches.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling findAllMatches.');
            }
            const localVarPath = `/api/match`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get hash for CRM generated match
         * @param {string} tenantId The tenant id
         * @param {number} id The crm id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMatchHash: async (tenantId: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling generateMatchHash.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling generateMatchHash.');
            }
            const localVarPath = `/api/match/generate-hash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get external id for match
         * @param {string} id The match id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalIdByHash: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getExternalIdByHash.');
            }
            const localVarPath = `/api/match/external-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTAuthentication required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MatchApi - functional programming interface
 * @export
 */
export const MatchApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a match
         * @param {MatchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMatch(body: MatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Booking>>> {
            const localVarAxiosArgs = await MatchApiAxiosParamCreator(configuration).createMatch(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a match
         * @param {MatchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMatchWithoutSale(body: MatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Booking>>> {
            const localVarAxiosArgs = await MatchApiAxiosParamCreator(configuration).createMatchWithoutSale(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all matches alive
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllMatches(start: number, size: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Booking>>>> {
            const localVarAxiosArgs = await MatchApiAxiosParamCreator(configuration).findAllMatches(start, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get hash for CRM generated match
         * @param {string} tenantId The tenant id
         * @param {number} id The crm id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMatchHash(tenantId: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<MatchHash>>> {
            const localVarAxiosArgs = await MatchApiAxiosParamCreator(configuration).generateMatchHash(tenantId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get external id for match
         * @param {string} id The match id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalIdByHash(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<MatchExternalId>>> {
            const localVarAxiosArgs = await MatchApiAxiosParamCreator(configuration).getExternalIdByHash(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MatchApi - factory interface
 * @export
 */
export const MatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a match
         * @param {MatchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMatch(body: MatchRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<Booking>> {
            return MatchApiFp(configuration).createMatch(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a match
         * @param {MatchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMatchWithoutSale(body: MatchRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<Booking>> {
            return MatchApiFp(configuration).createMatchWithoutSale(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all matches alive
         * @param {number} start Zero-based page index
         * @param {number} size The page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllMatches(start: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Booking>>> {
            return MatchApiFp(configuration).findAllMatches(start, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get hash for CRM generated match
         * @param {string} tenantId The tenant id
         * @param {number} id The crm id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMatchHash(tenantId: string, id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<MatchHash>> {
            return MatchApiFp(configuration).generateMatchHash(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get external id for match
         * @param {string} id The match id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalIdByHash(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<MatchExternalId>> {
            return MatchApiFp(configuration).getExternalIdByHash(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MatchApi - object-oriented interface
 * @export
 * @class MatchApi
 * @extends {BaseAPI}
 */
export class MatchApi extends BaseAPI {
    /**
     * 
     * @summary Create a match
     * @param {MatchRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public async createMatch(body: MatchRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<Booking>> {
        return MatchApiFp(this.configuration).createMatch(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create a match
     * @param {MatchRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public async createMatchWithoutSale(body: MatchRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<Booking>> {
        return MatchApiFp(this.configuration).createMatchWithoutSale(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all matches alive
     * @param {number} start Zero-based page index
     * @param {number} size The page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public async findAllMatches(start: number, size: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Booking>>> {
        return MatchApiFp(this.configuration).findAllMatches(start, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get hash for CRM generated match
     * @param {string} tenantId The tenant id
     * @param {number} id The crm id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public async generateMatchHash(tenantId: string, id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<MatchHash>> {
        return MatchApiFp(this.configuration).generateMatchHash(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get external id for match
     * @param {string} id The match id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public async getExternalIdByHash(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<MatchExternalId>> {
        return MatchApiFp(this.configuration).getExternalIdByHash(id, options).then((request) => request(this.axios, this.basePath));
    }
}
