<template>
  <div class="relative w-full">
    <img
      class="absolute w-full"
      src="../../assets/images/TAYKUS.svg"
      alt=""
    />
  </div>
  <header class="flex justify-between relative">
    <div
      v-if="selectedValue != 0"
      @click="moveIndicator(-1)"
      class="cursor-pointer back-button h-8 w-8 flex justify-center items-center"
    >
      <img src="../../assets/images/arrow-prev.svg" alt="" />
    </div>
    <div class="slide-position flex">
      <div
        v-for="(indicator, index) in indicators"
        :key="index"
        :class="[
          'indicator h-1 w-full',
          { 'select-indicator': index === selectedValue },
          { 'select-indicator': index + 1 === selectedValue },
          { 'select-indicator': index + 2 === selectedValue },
          { 'select-indicator': index + 3 === selectedValue },
          { 'select-indicator': index + 4 === selectedValue },
          { 'select-indicator': index + 5 === selectedValue },
          { 'select-indicator': index + 6 === selectedValue },
          { 'select-indicator': index + 7 === selectedValue },
        ]"
      ></div>
    </div>
  </header>
  <ActualLevelSelector v-if="selectedValue == 0" @showNextStep="moveIndicator"
    :propLevel="squash[0]"
    @selectedActualLevel ="handleSelectedOption"
  />
  <GenderSelector v-if="selectedValue == 1" @showNextStep="moveIndicator"
    :propGender="squash[1]"
    @selectedGender="handleSelectedOption"
  />
  <AgeSelector v-if="selectedValue == 2" @showNextStep="moveIndicator"
    :propAge="squash[2]"
    @selectedAge="handleSelectedOption"
   />
  <PlayedSelector v-if="selectedValue == 3" @showNextStep="moveIndicator"
    :propPlayed="squash[3]"
    @selectedPlayed="handleSelectedOption"
  />
  <MatchSelector v-if="selectedValue == 4" @showNextStep="moveIndicator"
    :propMatchPlayed="squash[4]"
    @selectedMatchPlayed="handleSelectedOption"
  />
  <LessonsSelector v-if="selectedValue == 5" @showNextStep="moveIndicator"
    :propLesson="squash[5]"
    @selectedLesson="handleSelectedOption"
  />
  <ConditionSelector v-if="selectedValue == 6" @showNextStep="moveIndicator"
    :propCondition="squash[6]"
    @selectedCondition="handleSelectedOption"
  />
  <InputLevel v-if="selectedValue == 7" @showNextStep="moveIndicator"
                :min="minLevel"
                :max="maxLevel"
                :sport="Sport.SQUASH"
                v-model="level"
                @level="handleLevel"
            />
  <!-- footer -->
  <div v-if="selectedValue < 7" class="next-button absolute w-full cursor-pointer">
    <div class="relative">
      <img class="button-next-img absolute" src="../../assets/images/arrow-prev.svg" alt=""
        @click="moveIndicator(1)"
      />
    </div>
  </div>
</template>
  
<script lang="ts">
import { defineComponent } from "vue";
import { Sport, MOD_LEVEL, UserGender } from "@/enum/constants";
import AgeSelector from "../../components/squashFormLeveling/ageSelector.vue";
import GenderSelector from "../../components/standartFormLeveling/genderSelector.vue";
import ActualLevelSelector from "../../components/squashFormLeveling/actualLevelSelector.vue";
import MatchSelector from "../../components/squashFormLeveling/matchSelector.vue";
import ConditionSelector from "../../components/squashFormLeveling/conditionSelector.vue";
import LessonsSelector from "../../components/squashFormLeveling/lessonsSelector.vue";
import PlayedSelector from "../../components/squashFormLeveling/playedSelector.vue";
import InputLevel from "@/components/form/inputLevel.vue";
import { AppsHelper } from '@/helpers/appsHelper';

export default defineComponent({
  name: "SquashForm",
  emits: ["userData"],
  components: {
    ActualLevelSelector,
    GenderSelector,
    AgeSelector,
    PlayedSelector,
    MatchSelector,
    ConditionSelector,
    LessonsSelector,
    InputLevel
  },
  data() {
    return {
      showModal: false,
      selectedValue: 0,
      indicators: [0, 1, 2, 3, 4, 5, 6, 7], // Add more indicators if needed
      minLevel: 0,
      maxLevel: 0,
      squash:[0.1,UserGender.MAN,0.2,0.1,0.1,0,0.4],//default option values
      Sport:Sport,
      level: 0
    };
  },
  methods: {
    handleSelectedOption(optionValue: number){
      this.squash[this.selectedValue] = optionValue;
    },
    moveIndicator(direction: any) {
      if(this.selectedValue == 0 && direction== -1){
        const jsonData = {
            closeButtonTapped: true,
        };
        try {
            AppsHelper.sendToIOS(window, jsonData);
        } catch (error) {
            console.error("Error sharing ios:", error);
        }
        if (AppsHelper.isAndroidWebView()) {
            const w = window as any;
            w.Android.closeButtonTapped();
        }
        return;
      }
      const newIndex = this.selectedValue + direction;
      this.level = this.squash.reduce((accumulator, currentValue, index) => {
        // Exclude this.padel[1], its gender, and gender don't calculate for level
        if (index !== 1) {
          return accumulator + currentValue;
        } else {
          return accumulator;
        }
      }, 0);
      this.minLevel = this.level - MOD_LEVEL;
      this.maxLevel = this.level + MOD_LEVEL;
      if (newIndex >= 0 && newIndex < this.indicators.length) {
        this.selectedValue = newIndex;
      }
    },
    handleLevel(level:number){
      let userData = {
          gender: this.squash[1],
          level: level
      };
      this.$emit("userData", userData);
    }
  },
});
</script>
<style scoped>
.taykus-logo {
  padding: 0 50px 0 16px;
}
header {
  padding: 0 8px;
  top: 20px;
  flex-wrap: wrap;
  margin-bottom: 50px!important;
}
.back-button {
  border-radius: 50%;
  padding: 10px 10px 8px 10px;
  background-color: #2B4164;
}
.slide-position {
  align-items: end;
  padding: 0 24px;
  gap: 8px;
  width: 100%;
  height: 4rem;
  margin-bottom: 3.5rem;
}
.indicator {
  background: #2b4164;
  border-radius: 30px;
}
.select-indicator {
  background-color: #1ac0ea;
}
.button-next-img {
  background: linear-gradient(271deg, #1ac0ea 3.29%, #0458e2 97.77%);
  box-shadow: -2px 4px 18px rgba(0, 15, 53, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 32px;
  height: 48px;
  width: 48px;
  padding: 16px 15px 14px 13px;
  bottom: 20px;
  transform: translate(-57%, 29px) rotate(180deg);
}
.button-next-b {
  bottom: -20px;
  transform: translate(-57%, 11px);
}
.next-button {
  bottom: 4%;
  left: 50%;
}
.next-button .relative {
  height: 100%;
}
h2 {
  font-size: 21px!important;
}
label {
  font-size: 18px!important;
}
p {
  font-size: 14px!important;
}
@media (max-width: 375px) {
  header {
    padding: 0 8px;
    top: 20px;
    flex-wrap: wrap;
    margin-bottom: 0px;
    height: 120px;
  }
  .slide-position {
    align-items: end;
    padding: 0 24px;
    gap: 8px;
    width: 100%;
    height: 0px;
    margin-bottom: 0px!important;
  }
}
@media (max-width: 320px) {
  header {
    padding: 0 8px;
    top: 20px;
    flex-wrap: wrap;
    margin-bottom: 0px!important;
    height: 120px;
  }
  .slide-position {
    align-items: end;
    padding: 0 24px;
    gap: 8px;
    width: 100%;
    height: 0px;
    margin-bottom: 0px;
  }
}
</style>