<template>
  <div class="flex items-center justify-center h-full">
    <div class="flex overflow-hidden min-w-120 md:min-w-180">
      <div class="w-full flex justify-center mb-4">
        <div v-if="players && players.length > 0" class="flex flex-col">
          <div v-for="player in filteredPlayers" :key="player.playerId" class="p-2 min-h-80 flex flex-col items-center">
            <div class="pb-2 relative">
              <div class="containerImageMatch br100">
                <img :alt="player.name" :src="player.image ? player.image : DEFAULT_IMAGE"/>
              </div>
              <div v-if="isMatch && hasLevel(player)"
                   class="absolute scoreUsers">
                <SvgContainer name="playerLevelStar"/>
                <div class="numberScore absolute">{{ player.level?.toFixed(2) }}</div>
              </div>
            </div>
            <div class="text-xs" :class="{ 'error-message': printPriceUsingRed(player) }">{{ getPlayerName(player) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SvgContainer from "@/views/SvgContainer.vue";
import {BookingStatus, BookingType, DEFAULT_IMAGE, GUEST, NO_LEVEL, PaymentType, PlayerType, UNKNOWN_PLAYER} from "@/enum/constants"
import {Booking, Participant} from 'models';
import {defineComponent, PropType} from 'vue';
import {Format} from "@/helpers/formatHelper";
import {addThreeDotsText} from "@/helpers/dataHelper";
import {hasLevel} from "@/helpers/bookingHelper";

export default defineComponent({
  name: "MatchTeam",
  props: {
    match: {
      type: Object as PropType<Booking>,
      required: true,
    },
    team: {
      type: Number,
      required: true,
    },
    disablePlayerClick: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  components: {
    SvgContainer,
  },
  computed: {
    filteredPlayers() {
      return this.players.filter((player) => player.team === this.team);
    },
  },
  mounted() {
    if (this.match?.players) {
      this.players = this.match.players;
    }
    this.isMatch = this.match?.type?.code === BookingType.MATCH;
  },
  data() {
    return {
      DEFAULT_IMAGE: DEFAULT_IMAGE,
      UNKNOWN_PLAYER: UNKNOWN_PLAYER,
      playerType: PlayerType,
      players: [] as Participant[],
      NO_LEVEL: NO_LEVEL,
      isMatch: false,

    };
  },
  methods: {
    hasLevel,
    getPlayerName(player: Participant) {
      if (player.type!.code === PlayerType.EMPTY) {
        return this.$t(UNKNOWN_PLAYER);
      }
      if (player.type!.code === PlayerType.CUSTOMER && Format.IsNull(player.name)) {
        return this.$t(GUEST);
      }
      return addThreeDotsText(player.name!);
    },
    printPriceUsingRed(player: Participant) : boolean{
      return !player.paid && (player.type?.code != PlayerType.EMPTY) && 
             this.match.status?.code != BookingStatus.CANCELED
    }
  }
});
</script>

<style scoped>

.containerImageMatch {
  width: 44px;
  border-radius: 50%;
  overflow: hidden;
}

.scoreUsers {
  top: -8px;
  font-size: 10px;
  right: -14px;
  font-weight: bold;
  color: black;
}

.numberScore {
  top: 10px;
  left: 5px;
}
</style>

