import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25c256a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-bar" }
const _hoisted_2 = { class: "level-labels" }
const _hoisted_3 = {
  key: 0,
  class: "init-level"
}
const _hoisted_4 = {
  key: 1,
  class: "init-level"
}
const _hoisted_5 = {
  key: 2,
  class: "level"
}
const _hoisted_6 = {
  key: 3,
  class: "last-level"
}
const _hoisted_7 = {
  key: 4,
  class: "last-level"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "progressBar br16 gradiend h-full",
      style: _normalizeStyle({ width: _ctx.barWidth + '%' })
    }, null, 4),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.firstValue<_ctx.MIN_LEVEL)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.NO_LEVEL_STRING_MARK), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.firstValue), 1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levelsInRange, (level) => {
        return (_openBlock(), _createElementBlock("span", {
          class: "level",
          key: level
        }, _toDisplayString(level), 1))
      }), 128)),
      (_ctx.showMidLevel)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.midLevel), 1))
        : _createCommentVNode("", true),
      (_ctx.lastValue<_ctx.MIN_LEVEL)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.NO_LEVEL_STRING_MARK), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.lastValue), 1))
    ])
  ]))
}