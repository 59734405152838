<template>
  <div id="slider-wrapper" class="wrapper-scroll-js flex flex-row items-center align-middle pt-2 pb-4" ref="wrapper">
    <div v-if="showSlideButtons" @click="moveLeft" class="walletButton back flex items-center justify-center scroll-button"><SvgContainer name="profileBackArrow" /></div>
    <div :class="{'bg-slate-700': showSlideButtons}" class="container-scroll-js flex" ref="container">
      <div class="content-scroll-js flex" ref="content">
        <div class="container-hours flex">
          <div
            v-for="(hour, index) in fullHours"
            :key="index"
          >
            <div class="walletButton pl-2 pr-2 cursor-pointer" to="#" 
              @click="hourSelected(hour.value)"
            >
              {{ hour.label }}
            </div>
          </div>
          <div v-if="showShowMore"
            @click="showMore"
           >
            <div class="walletButton pl-2 pr-2 cursor-pointer">{{$t("show_more")}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showSlideButtons" @click="moveRight" class="walletButton back flex items-center justify-center scroll-button right"><SvgContainer name="profileBackArrow" /></div>
  </div>
</template>

<script>
import { BookingHelper } from "@/helpers/bookingHelper";
import { getSearchDataToSend } from "@/helpers/dataHelper";
import { useStore } from "vuex";
import { SearchType } from "@/enum/constants";
import SvgContainer from "@/views/SvgContainer.vue";

export default {
  created() {
    this.store = useStore();
    this.fullHours = this.hours;
  },
  components: {
    SvgContainer,
  },
  props: {
    hours: {
      type: Array,
      required: false,
    },
    tenantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fullHours: [],
      showShowMore: true,
      showSlideButtons: false
    };
  },
  watch: {
    fullHours: {
      deep: true,
      handler() {
        this.checkSliderButtons();
      },
    },
  },
  beforeMount(){
    this.checkSliderButtons();
  },
  emits: ["hourSelected"],
  methods: {
    checkSliderButtons(){
      if(this.fullHours && this.fullHours.length > 2){
        this.showSlideButtons = true;
      }
    },
    async showMore() {
      let dataToSend = getSearchDataToSend();
      let arrayTenant = [];
      arrayTenant[0] = this.tenantId;
      dataToSend.tenantIds = arrayTenant;
      dataToSend.searchType = SearchType.FULL;
      this.store.commit("setLoading", true);
      const message = await BookingHelper.messageAvailability(dataToSend);
      const tenant = message.bookings[0];
      this.store.commit("setLoading", false);
      // Merge arrays
      this.fullHours = [...this.fullHours, ...tenant.times];
      const map = new Map();
      this.fullHours.forEach(obj => map.set(obj.value, obj));
      this.fullHours = Array.from(map.values());
      this.showShowMore = false;
    },
    hourSelected(hour) {
      this.$emit("hourSelected", hour);
    },
    moveLeft() {
      const container = this.$refs.container;
      container.scrollBy({ left: -200, behavior: 'smooth' });
    },
    moveRight() {
      const container = this.$refs.container;
      container.scrollBy({ left: 200, behavior: 'smooth' });
    },
  },
};
</script>
<style scoped>
#slider-wrapper .container {
  max-width: 960px;
}
#slider-wrapper .container.container-sm {
  max-width: 620px;
}
.wrapper-scroll-js {
  position: relative;
  overflow: hidden;
}
.wrapper-scroll-js .container-scroll-js {
  display: block;
  overflow: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.wrapper-scroll-js .container-scroll-js .content-scroll-js {
  display: inline-block;
  white-space: nowrap;
}
.wrapper-scroll-js:before,
.wrapper-scroll-js:after {
  opacity: 0;
  display: block;
  content: "";
  position: absolute;
  height: 100%;
  box-shadow: 0 0px 8px 8px rgba(0, 0, 0, 0.45);
  border-radius: 100%;
  z-index: 2;
  visibility: hidden;
  transition: 0.6s;
  width: 35px;
  left: -35px;
}
.wrapper-scroll-js:after {
  top: 0;
  left: auto;
  right: -35px;
}
.wrapper-scroll-js.scroll-right:after {
  opacity: 1;
  visibility: visible;
}
.wrapper-scroll-js.scroll-left:before {
  opacity: 1;
  visibility: visible;
}
#slider-wrapper .container-hours {
  gap: 10px;
}
#slider-wrapper .container-hours div {
  border-radius: 16px;
}
.container-hours {
  gap: 10px;
}
.container-scroll-js::-webkit-scrollbar {
  -webkit-appearance: none;
}
.scroll-button {
  height: 38px;
  border-radius: 0px;
  cursor: pointer;
}
.right {
  transform: rotate(180deg);
}
</style>