<template>
  <div class="flex flex-wrap paddig-responsive">
    <div class="flex w-full text-left center-responsive">
      <h2>{{ $t("how_played_sport_regularly_question", { sport: getSportLabel(Sport.SQUASH)}) }}</h2>
    </div>
    <div class="flex w-full mt-8">
      <div class="flex flex-col">
        <template v-for="option in options" :key="option.id">
          <label class="flex items-center mb-2">
            <input type="radio" :value="option.value" v-model="played" @change="showValue()" class="mr-2">
            <span>{{ option.label }}</span>
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { SelectOption } from "@/custom-models/levelingSelector";
import { getSportLabel } from "@/helpers/dataHelper";
import { Sport } from "@/enum/constants";
  
export default defineComponent({
  name: 'PlayedSelector',
  props: {
    propPlayed:{ 
      type: Number,
      default: 0.0 
    }
  },
  created (){
    this.played = this.propPlayed;
  },
  data() {
    return {
      Sport:Sport,
      played: 0.1,
      options: [
        { id: 1, label: this.$t("less_than_a_year"), value: 0.1 },
        { id: 2, label: this.$t("less_than_2_years"), value: 0.2 },
        { id: 3, label: this.$t("more_than_2_years"), value: 0.3 },
      ] as SelectOption[]
    };
  },
  methods: {
    getSportLabel,
    showValue() {
      this.$emit("selectedPlayed", this.played);
    }
  }
});
</script>