<template>
  <div class="md:flex containerMapsResults md:pb-8">
    <div class="CalendarMaps md:mt-9 md:p-8 sm:mt-4">
      <div class="h-full">
        <div class="sm:p-5 MapsText relative sm:flex sm:flex-col">
          <span>{{ $t("club_info") }}</span>
          <div class="mt-3 flex">
            <SportSelector
              :sports="tenantInfo.tenant.sports"
              :showLabels="false"
              :titleTop="false"
            />
          </div>
          <div
            class="flex sm:justify-center sm:mt-5 md:absolute IconsMaps top-0 right-0"
          >
            <span
              @click="openGoogleMaps(9)"
              class="mr-8 flex justify-center flex-col items-center text-center cursor-pointer"
            >
              <SvgContainer name="directionRightIcon" />
            </span>
            <span
              @click="showPhoneModal = true"
              class="flex justify-center flex-col items-center text-center cursor-pointer"
            >
              <SvgContainer name="roundedTelfIcon" />
            </span>
          </div>
        </div>
        <div class="MapResuls h-full overflow-hidden md:mt-8 sm:mt-2">
          <div id="map"></div>
        </div>
      </div>
    </div>
    <div class="CalendarMaps md:mt-9 md:p-8 sm:p-5 sm:pb-9">
      <div class="h-full">
        <div class="text-lg">{{ $t("opening_hours") }}</div>
        <div class="mt-5 OpeningOpacityText flex justify-between pb-5 sm:text-sm" :class="{ OpeningResults: !isLinked }">
          <span>{{ $t("opening_hours") }}</span>
          <span>{{ tenantInfo.tenant.openHours }}</span>
        </div>

        <LinkPrivate v-if="!isLinked"
          :booking="tenantInfo"
          @linkClosed="showPopLinkPrivate = false"
        />
      </div>
    </div>

    <Transition>
      <PopUpLight v-if="showPhoneModal" @closed="showPhoneModal = false">
        <div
          class="mt-5 OpeningOpacityText flex justify-evenly pb-5 OpeningResults sm:text-sm"
        >
          <span> {{ $t("phone") }} </span>
          <span>
            <a :href="`tel:${tenantInfo.tenant.phone}`">{{
              tenantInfo.tenant.phone
            }}</a>
          </span>
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight
        v-if="showPopLinkPrivate"
        @closed="showPopLinkPrivate = false"
      >
        <div class="mt-5 flex justify-evenly sm:text-sm">
          <span> {{ $t("the_club_is_private") }} </span>
        </div>
        <div class="flex justify-evenly sm:text-sm">
          <LinkPrivate
            :booking="tenantInfo"
            @linkClosed="showPopLinkPrivate = false"
          />
        </div>
      </PopUpLight>
    </Transition>
  </div>
</template>
<script>
import PopUpLight from "../popup/popupLight.vue";
import { AppsHelper } from "@/helpers/appsHelper";
import LinkPrivate from "@/components/form/linkPrivate.vue";
import CustomerHelper from "@/helpers/customerHelper";
import SportSelector from "@/components/form/sportSelector.vue";
import SvgContainer from "@/views/SvgContainer.vue";

export default {
  name: "homeBooking",
  data() {
    return {
      showPhoneModal: false,
      showPopLinkAccount: false,
      showPopLinkPrivate: false,
      isLinked: true
    };
  },
  components: {
    PopUpLight,
    LinkPrivate,
    SportSelector,
    SvgContainer
  },
  props: ["tenantInfo", "showPrivate"],
  async mounted() {
    this.isLinked = await CustomerHelper.customerIsLinked(this.tenantInfo.tenant.id);
    this.loadMap();
    this.showPhoneModal = false;
    this.fixIphoneHeight();
    if (this.showPrivate) {
      this.showPopLinkPrivate = this.showPrivate;
    }
  },
  methods: {
    loadMap() {
      var mapContainer = document.getElementById("map");
      if (mapContainer == null) {
        return;
      }

      var mapUrl =
        "https://www.google.com/maps/embed/v1/place?key=" +
        process.env.VUE_APP_GOOGLE_API_KEY +
        "&q=" +
        this.tenantInfo.tenant.cordX +
        "," +
        this.tenantInfo.tenant.cordY +
        "";

      mapContainer.innerHTML =
        '<iframe frameborder="0" style="border:0" src="' +
        mapUrl +
        '" allowfullscreen></iframe>';
    },
    openGoogleMaps() {
      const jsonData = {
        locationTapped: {
          cordX: this.tenantInfo.tenant.cordX,
          cordY: this.tenantInfo.tenant.cordY,
          name: this.tenantInfo.tenant.name,
        },
      };
      if (AppsHelper.sendToIOS(window, jsonData)) {
        return;
      }
      window.open(
        `https://www.google.com/maps/place/${this.tenantInfo.tenant.cordX},${this.tenantInfo.tenant.cordY}`
      );
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const searchContainer = document.querySelector(".containerMapsResults");
        if (searchContainer) {
          searchContainer.classList.add("pb-100");
        }
      }
    },
  },
};
</script>