import { LevelProvider, NO_LEVEL } from "@/enum/constants";
import { Format } from "./formatHelper";
import { MatchApi } from "@/service/MatchApi";
import { AxiosResponse } from "axios";
import { MatchRequest } from 'models/match-request';
import { Tenant } from "models";
import { SportLevel } from "@/custom-models/sportLevel"
import { SportData } from "@/custom-models/sportData"

export default class MatchHelper {

  static async createMatch(body: MatchRequest) {
    const response = await MatchApi.createMatch(body)
    return response;//not call checkResponse because status is needed
  }

  static async createMatchWithoutSale(body: MatchRequest) {
    const response = await MatchApi.createMatchWithoutSale(body)
    return this.checkResponse(response);
  }

  static async findAllMatches(start: number, size: number) {
    const response = await MatchApi.findAllMatches(start, size)
    return this.checkResponse(response);
  }
  
  static checkResponse(response:AxiosResponse) {
    if(!Format.IsNull(response.data)){
      return response.data
    }
    return null;
  }

  static getLevelProviderBySport(dataArray: SportData[], sport: number): number {
    const item = dataArray.find((obj) => obj.sport === sport);
    return item ? item.levelProvider : LevelProvider.PANDORA;
  }
  static getLevelBySportAndProvider(data: SportLevel[], sportCode: number, providerCode: number): number {
    const sport = data.find((item) => item.sport.code === sportCode);
  
    if (!sport) {
      return NO_LEVEL; //defaul if sport not found
    }
  
    const levelProvider = sport.levels.find(
      (level) => level.levelProvider.code === providerCode
    );
  
    return levelProvider ? levelProvider.level : NO_LEVEL;
  }
  static setLevel(sport: number, levelProvider: number, allLevels: SportLevel[]): number {
    if (allLevels.length === 0) {
      return NO_LEVEL;
    }
    const sportData = allLevels.find((item) => item.sport.code === sport);
    if (sportData) {
      const levelData = sportData.levels.find(
        (level) => level.levelProvider.code === levelProvider
      );
      if(levelData?.level){
        return levelData.level;
      }
    }
    return NO_LEVEL;
  }

  static getTenantLevelProviderBySport(tenant: any, sport: number) {
    const tenantData = tenant;
    const defaultLevelProviders = tenantData.defaultLevelProviders || {};
    const levelProvider = defaultLevelProviders.find((levelProvider: { sport: number; }) => levelProvider.sport === sport);

    return levelProvider ? levelProvider.levelProvider : null;
  }
}

