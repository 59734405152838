<template>
  <div v-if="showForm">
    <div class="modal-header">
      <h4 class="modal-title text-center">
        <header>
          <img
            class="inline-block logo"
            alt="Taykus logo"
            src="../../assets/images/header-popup.png"
          />
        </header>
      </h4>
    </div>
    <h3 class="mt-4">{{ recoverTitle }}</h3>
    <p class="mt-4">{{ recoverMsg }}</p>
    <form>
      <InputEmail v-model="email" v-if="phase === 0" />
      <InputPassword @passwordValidated="handlePasswordValidated" v-else />
      <div class="flex justify-center w-full mt-8 input-component">
        <input
          type="button"
          @click="sendRecovery"
          :value="recoverSend"
          :disabled="hasErrors"
          :class="{ 'button-disabled': hasErrors, '': true }"
        />
      </div>
      <div class="mt-4 cursor-pointer" @click="close" v-if="phase === 0">
        <p class="mt-2">{{ $t("back_to_login") }}</p>
      </div>
    </form>
    <div id="loginError" class="flex justify-center w-full mt-4">
      <p class="form-error">{{ loginError }}</p>
    </div>
  </div>
  <div v-else class="mt-14">
    <div class="modal-header">
      <h4 class="modal-title text-center">
        <header>
          <img
            class="inline-block logo"
            alt="Mail send"
            src="../../assets/images/recoveryMail/sendRecMail.png"
          />
        </header>
      </h4>
    </div>
    <h3 class="mt-4">{{ $t("recover_email_send") }}</h3>
    <p class="mt-4">{{ $t("check_email_message") }}</p>
    <div class="flex justify-center w-full mt-8 input-component">
      <input
        type="button"
        @click="close"
        :value="$t('back_to_login')"
        :disabled="hasErrors"
        :class="{ 'button-disabled': hasErrors, '': true }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import InputPassword from "../../components/form/inputPassword.vue";
import InputEmail from "../../components/form/inputEmail.vue";
import { CustomerApi } from "../../service/CustomerApi";

export default defineComponent({
  name: "recoverPassword",
  emits: ["closeRecover"],
  components: {
    InputEmail,
    InputPassword,
  },
  props: {
    phase: {
      type: Number,
      required: true,
      default: 0,
    },
    token: {
      type: String,
      required: false,
    },
  },
  computed: {
    hasErrors(): boolean {
      return this.password == null || this.email == null;
    },
    recoverTitle(): string {
      if (this.phase === 0) {
        return this.$t("forgot_password");
      } else {
        return this.$t("restore_password");
      }
    },
    recoverMsg(): string {
      if (this.phase === 0) {
        return this.$t("no_worries_we_ll_send_you_an_email");
      } else {
        return this.$t("write_new_password");
      }
    },
    recoverSend(): string {
      if (this.phase === 0) {
        return this.$t("send_mail");
      } else {
        return this.$t("restore_password");
      }
    },
  },

  data() {
    return {
      openRestore: false,
      error: "",
      showForm: true,
      email: "",
      password: "",
      loginError: "",
    };
  },
  mounted() {
    this.loginError = "";
  },

  methods: {
    async sendRestoreEmail(email: string) {
      const response = await CustomerApi.initRestorePassword(email);
      const statusCode = response.request.status;
      if (statusCode == 200) {
        this.showForm = false;
      }
    },
    async restorePassword(password: string) {
      if (this.token != undefined && this.token != null && this.token != "") {
        const response = await CustomerApi.restorePassword(
          this.token,
          password
        );
        const statusCode = response.request.status;
        if (statusCode == 200) {
          this.close();
        }
      }
      else {
        this.loginError = "token null";
      }
    },
    handlePasswordValidated(password: any) {
      this.password = password;
    },
    close() {
      this.openRestore = false;
      this.$emit("closeRecover");
    },
    async sendRecovery() {
      if (this.phase === 0) {
        this.sendRestoreEmail(this.email);
        return;
      }
      this.restorePassword(this.password);
    },
  },
});
</script>
<style scoped>
h3 {
  font-weight: 500 !important;
  font-size: 32px !important;
}
.button-disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}
</style>